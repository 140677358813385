document.addEventListener('DOMContentLoaded', () => {
        function sliceSize(dataNum, dataTotal) {
          return (dataNum / dataTotal) * 360;
        }
    
        function addSlice(sliceSize, pieElement, offset, sliceID, color) {
          // Append the slice with a span (clipped element)
          jQuery(pieElement).append(
            "<div class='slice " + sliceID + "'><span></span></div>"
          );
    
          // Rotate the slice (div) and its content (span)
          jQuery("." + sliceID).css({
            transform: "rotate(" + offset + "deg) translate3d(0,0,0)",
          });
    
          jQuery("." + sliceID + " span").css({
            transform: "rotate(" + sliceSize + "deg) translate3d(0,0,0)",
            "background-color": color,
            clip: `rect(0px, ${jQuery(pieElement).width()}px, ${jQuery(pieElement).width()}px, ${
              jQuery(pieElement).width() / 2
            }px)`, // Adjust clipping dynamically based on pie width
          });
        }
    
        function iterateSlices(
          sliceSize,
          pieElement,
          offset,
          dataCount,
          sliceCount,
          color
        ) {
          var sliceID = "s" + dataCount + "-" + sliceCount;
          var maxSize = 179;
          if (sliceSize <= maxSize) {
            addSlice(sliceSize, pieElement, offset, sliceID, color);
          } else {
            addSlice(maxSize, pieElement, offset, sliceID, color);
            iterateSlices(
              sliceSize - maxSize,
              pieElement,
              offset + maxSize,
              dataCount,
              sliceCount + 1,
              color
            );
          }
        }
        function createPie(dataElement, pieElement) {
          jQuery(pieElement).empty();

          var listData = [];
          jQuery(dataElement + " span").each(function () {
            listData.push(Number(jQuery(this).html()));
          });
          var listTotal = 0;
          for (var i = 0; i < listData.length; i++) {
            listTotal += listData[i];
          }
          var offset = 0;
          var color = ["#2C1D2E", "#5A2835", "#E1E17D", "#452141", "#C3653E"];
    
          for (var i = 0; i < listData.length; i++) {
            var size = sliceSize(listData[i], listTotal);
            iterateSlices(size, pieElement, offset, i, 0, color[i]);
    
            jQuery(dataElement + " li:nth-child(" + (i + 1) + ") .legend-circle").css(
              "background",
              color[i]
            );
    
            offset += size;
          }
        }
    
        createPie(".pieID.legend", ".pieID.pie");
});
